import React from "react";
import './style.scss';

const LightThemeIcon = () => {

    return <div className='circle blackCircle'>
        <svg width="15" height="15" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.2259 0.213964C14.7675 0.671703 15.0622 0.965964 16.6338 1.78336C18.8603 2.89501 20.7593 4.88944 21.8398 7.14544C22.5601 8.68214 22.6911 9.43414 22.6911 11.7555C22.6911 15.1232 21.807 17.3138 19.5478 19.5698C17.2886 21.8258 15.0949 22.7086 11.7225 22.7086C9.39779 22.7086 8.64473 22.5778 7.10585 21.8585C4.84664 20.7795 2.84938 18.8832 1.73615 16.6599C0.393723 14.0442 -0.424829 14.6981 0.230013 17.9023C1.76889 25.7493 9.49602 31.1114 17.3541 29.8035C23.7388 28.7246 28.7156 23.7548 29.7961 17.4119C31.073 9.82649 26.2272 2.43727 18.762 0.475529C16.6665 -0.0476015 15.5533 -0.145688 15.2259 0.213964ZM23.6733 4.4971C28.2245 8.2244 29.8943 14.4366 27.7988 19.9294C24.7538 27.9072 15.1277 31.1441 7.79343 26.6648C5.86165 25.4877 3.14406 22.4143 2.5547 20.8122L2.22728 19.7987L3.11132 20.6488C6.81117 24.0818 11.657 24.9973 16.4701 23.1663C19.1877 22.1528 22.1345 19.2101 23.1495 16.4964C24.9503 11.7555 24.1317 7.17814 20.792 3.35275L19.8425 2.27379L20.8248 2.63344C21.3486 2.79692 22.6256 3.64701 23.6733 4.4971Z" fill="white" />
        </svg>

    </div>

}
export default LightThemeIcon   
import React from "react";
import styled from "styled-components";

const MainBrand = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   img{
    // width : ${props => props.width};
    width : 120px;
    max-width: 100%;
    // height: ${props => props.height};
   }
   .v-light & , & {
        .logo-dark{
           display : block ;     
        }
        .logo-light{
            display : none;    
        }
   }
    
   .v-dark & {
       .logo-dark{
           display : none ;     
        }
        .logo-light{
            display : block;    
        }
   }
`;

const Brand = ({width, height, alt}) => {

    return (
        <MainBrand className="main-brand" width={width} height={height}>
            <a href="#start">
                {/* <h5 className="logo-light">InSol Group</h5> */}
                <img src={require("../../assets/img/isg_site_logo_white.png")} className="logo-light" alt="logoLight" style={{width: `${width}`}}/>
            </a>
            <a href="#start">
                {/* <h5 className="logo-dark">InSol Group</h5> */}
                <img src={require("../../assets/img/isg_site_logo_black.png")} className="logo-dark" alt="logoDark" style={{width: `${width}`}} />
            </a>
        </MainBrand>
    );
}

MainBrand.defaultProps = {width: '80px', height: 'auto'}
Brand.defaultProps = {alt: "Droow"}

export default React.memo(Brand);
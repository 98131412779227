import React from 'react';
import {dsnCN} from "../../../hooks/helper";

function MenuContent({className}) {
    const socialData = [
        {link: "https://www.linkedin.com/company/insol-group/about/", name: "Linkedin."},
        {link: "https://instagram.com/insol_group?igshid=YmMyMTA2M2Y=", name: "Instagram."}
    ];
    return (
        <div className={dsnCN('container-content  d-flex flex-column justify-content-center', className)}>
            <div className="nav__info">
                <div className="nav-content">
                    <h5 className="sm-title-block mb-10">Studio</h5>
                    <p>
                        Armenia, Yerevan,  <br/> 59 Komitas Ave
                    </p>

                </div>
                <div className="nav-content mt-30">
                    <h5 className="sm-title-block mb-10">Contact</h5>
                    <p className="links over-hidden" style={{ letterSpacing: '0.5px' }}>
                        <span>E</span> <span>:</span> <a className="link-hover" href="mailto:hello@insolgroup.dev"
                            data-hover-text="hello@insolgroup.dev" target="_blank">hello@insolgroup.dev</a>
                    </p>
                    <p className="links over-hidden" style={{ letterSpacing: '0.5px' }}>
                        <span>TG</span> <span>:</span> <a className="link-hover" href="http://t.me/insol_group"
                            data-hover-text="@insol_group" target="_blank">@insol_group</a>
                    </p>
                </div>
            </div>
            <div className="nav-social nav-content mt-30">
                <div className="nav-social-inner p-relative">
                    <h5 className="sm-title-block mb-10">Follow us</h5>
                    <ul>
                        {socialData.map((item, index) =>
                            <li key={index} style={{marginRight: '15px'}}>
                                <a href={item.link} target="_blank" rel="nofollow noopener noreferrer">{item.name}</a>
                            </li>)}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default MenuContent;